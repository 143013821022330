// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBDWOqXyMR7W9p934Ex6dDNZxRfC9lEqow",
    authDomain: "companionapp-76430.firebaseapp.com",
    databaseURL: "https://companionapp-76430-default-rtdb.firebaseio.com",
    projectId: "companionapp-76430",
    storageBucket: "companionapp-76430.appspot.com",
    messagingSenderId: "283808687917",
    appId: "1:283808687917:web:3c34b7bec9a33fc12615a4",
    measurementId: "G-0H2KNKV8M4"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
