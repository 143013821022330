/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
// tslint:disable variable-name
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  get loggedIn() {
    return this.__loggedIn;
  }
  get companyId() {
    return this.__companyId;
  }
  get distribuidora() {
    return this.__distribuidora;
  }
  __loggedIn = false;
  __companyId = '';
  __distribuidora = '';
  constructor(
    private afAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private navCtrl: NavController
  ) {
    this.afAuth.authState.subscribe((authState) => {
      if (authState) {
        this.firestore
          .doc(`users/${authState.uid}`)
          .get()
          .subscribe((user) => {
            const userData: any = user.data();
            this.__companyId = userData.company.id;
            this.__distribuidora = userData.distribuidora;
            this.__loggedIn = true;
            this.navCtrl.navigateRoot('lojas');
          });
      } else {
        this.__loggedIn = false;
        this.__companyId = undefined;
        this.navCtrl.navigateRoot('login');
      }
    });
  }

  async loginEmail(email, password) {
    try {
      await this.afAuth.signInWithEmailAndPassword(email, password);
    } catch (e) {
      console.log(e);
    }
  }
  logout() {
    this.afAuth.signOut();
  }
}
