import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'lojas',
    pathMatch: 'full'
  },
  {
    path: 'checklist',
    loadChildren: () => import('./pages/checklist/checklist.module').then( m => m.ChecklistPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'lojas',
    loadChildren: () => import('./pages/lojas/lojas.module').then( m => m.LojasPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'lojas',
    pathMatch: 'prefix'
  },
  {
    path: 'nota',
    loadChildren: () => import('./pages/nota/nota.module').then( m => m.NotaPageModule)
  },
  {
    path: 'confirma-loja',
    loadChildren: () => import('./pages/confirma-loja/confirma-loja.module').then( m => m.ConfirmaLojaPageModule)
  },
  {
    path: 'sortimento',
    loadChildren: () => import('./pages/sortimento/sortimento.module').then( m => m.SortimentoPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
